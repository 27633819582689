export default theme => ({
  ...theme,
  sizes: {
    ...theme.sizes,
    container: 1140
  },
  progress: {
    color: "red"
  }
});
