import React, { createContext, useState, useEffect } from "react";
import firebase from "gatsby-plugin-firebase";
import navigate from "gatsby";
import { getUserQuestionStatuses } from "@elegantstack/core-blog/src/firebase";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({
    providerData: [{ displayName: "Hacker" }]
  });
  console.log("User on Auth Provider:");
  console.log(user);
  /*useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        const userId = user?.providerData ? user.providerData[0].uid : null;
        getUserQuestionStatuses(user.uid).then((data) => {
          //Finally set context
          setUser({ ...user, ...data });
        });
      }
    });
  }, []);*/
  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const logout = () => {
  return firebase
    .auth()
    .signOut()
    .then(function() {
      console.log(`log out successfull...`);
      const traits = {
        birthday: null,
        dob: null,
        email: null,
        facebook_profile: null,
        firebaseAuth: null,
        firstName: null,
        id: null,
        lastName: null,
        log_in_type: null,
        name: null
      };
      return traits;
    })
    .catch(function(error) {
      console.error("Logout failed ", error);
      throw new Error("Logout failed");
    });
};

export default AuthProvider;
