/*
Firebase Terminology

Data is stored in documents.
Documents are saved to collections.

Document: {key: value}
Collections: [{key: value}, {key: value}, ..., {key: value}]

Tutorial: https://retool.com/blog/crud-with-cloud-firestore-using-the-nodejs-sdk/
*/

import firebase from "firebase/app";
import "firebase/firestore";
const db = firebase.firestore();

const COLLECTIONS = {
  USERS: "USERS"
};

const COURSES = {
  ALGORITHMS: "algorithms",
  SYSTEM_DESIGN: "system_design",
  DATA_STRUCTURES: "data_structures",
  BLOCKCHAIN: "blockchain"
};

const QUESTION_STATUS = {
  COMPLETE: "complete",
  REVIEW_AGAIN: "review-again"
};

// A method to query firebase and create a new user
export const createUser = async (userId, userData) => {
  if (!userId) return;

  const isUserExist = await getUser(userId);
  if (isUserExist) {
    return console.log("user exists! avoiding setting new one");
  }

  const createdUser = await db.collection(COLLECTIONS.USERS).doc(userId);
  const result = await createdUser.set(userData);
  console.log("create user result:");
  console.log(result);
  createdUser
    .collection("algorithms")
    .doc("questions")
    .set({
      paid: false,
      [QUESTION_STATUS.COMPLETE]: [],
      [QUESTION_STATUS.REVIEW_AGAIN]: []
    });
};

// A method to query firebase and get a new user by id
export const getUser = async userId => {
  if (!userId) return;
  const user = await db
    .collection(COLLECTIONS.USERS)
    .doc(userId)
    .get();
  return user.exists ? user.data() : null;
};

export const getUserQuestionStatuses = async (uid, qid) => {
  if (!uid) return;
  const user = await db
    .collection(COLLECTIONS.USERS)
    .doc(uid)
    .get();

  const statuses = await user.ref
    .collection("algorithms")
    .doc("questions")
    .get();
  return statuses.exists ? statuses.data() : {};
};

// A method to delete a user
export const deleteUser = async userId => {
  // deleting a user doc will not delete all subcollections
  const user = await db.collection(COLLECTIONS.USERS).doc(userId);
  await user
    .collection(COURSES.ALGORITHMS)
    .doc("complete")
    .delete();
  await user
    .collection(COURSES.ALGORITHMS)
    .doc("review-again")
    .delete();
  await user.delete();
};

export const deleteSeedUsers = async () => {
  FAKE_USERS.forEach(async u => {
    await db
      .collection(COLLECTIONS.USERS)
      .doc(u.id)
      .delete();
  });
};

// A method to query firebase and set a user question review again
export const updateUserQuestionStatusReviewAgain = async (uid, qid) => {
  if (!uid) return;

  const user = await db
    .collection(COLLECTIONS.USERS)
    .doc(uid)
    .get();
  if (!user.exists) {
    console.log(`could not find user to update`);
  }
  await user.ref
    .collection("algorithms")
    .doc("questions")
    .update({
      "review-again": firebase.firestore.FieldValue.arrayUnion(qid)
    });
  return;
};

// A method to query firebase and set a user question complete
export const updateUserQuestionStatusComplete = async (uid, qid) => {
  if (!uid) return;
  const user = await db
    .collection(COLLECTIONS.USERS)
    .doc(uid)
    .get();
  if (!user.exists) {
    console.log(`could not find user to update`);
  }
  await user.ref
    .collection("algorithms")
    .doc("questions")
    .update({
      complete: firebase.firestore.FieldValue.arrayUnion(qid)
    });
  return;
};

export const updateUserQuestionStatusIncomplete = async (uid, qid) => {
  if (!uid) return;
  const user = await db
    .collection(COLLECTIONS.USERS)
    .doc(uid)
    .get();
  if (!user.exists) {
    console.log(`could not find user to update`);
  }
  await user.ref
    .collection("algorithms")
    .doc("questions")
    .update({
      complete: firebase.firestore.FieldValue.arrayRemove(qid)
    });
  return;
};

export const createSeedUsers = async () => {
  FAKE_USERS.forEach(async u => {
    const createdUser = await db.collection(COLLECTIONS.USERS).doc(u.id);

    await createdUser.set({
      address: u.address,
      age: u.age,
      first: u.first,
      id: u.id,
      last: u.last
    });
    createdUser
      .collection("algorithms")
      .doc("questions")
      .set({
        paid: false,
        [QUESTION_STATUS.COMPLETE]: [],
        [QUESTION_STATUS.REVIEW_AGAIN]: []
      });
    console.log("***** created user ******");
    console.log(createdUser);
  });
};

const FAKE_USERS = [
  {
    id: "sophie_bush",
    first: "Sophie",
    last: "Bush",
    address: "13 5th St., San Francisco, CA",
    birthday: "05/13/1991",
    age: "30"
  },
  {
    id: "jlo",
    first: "Jennifer",
    last: "Lopez",
    address: "130 5th St., San Francisco, CA",
    birthday: "05/13/1993",
    age: "30"
  },
  {
    id: "arod",
    first: "Alex",
    last: "Rodriguez",
    address: "130 5th St., San Francisco, CA",
    birthday: "05/13/1993",
    age: "30"
  }
];
